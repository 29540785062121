import React from "react"
import BackgroundImage from "gatsby-background-image"
import responsiveImage from "../functions/responsiveImage.js"
import "../styles/components/_imageSection.scss"

const ImageSection = ({ images, locationIcon, text }) => {
  return (
    <BackgroundImage
      fluid={responsiveImage(images[0], images[1])}
      className="image-section"
    >
      <div className="description">
        <span className="description-icon">
          <img src={locationIcon.file.url} alt={locationIcon.description} />
        </span>
        {text}
      </div>
    </BackgroundImage>
  )
}

export default ImageSection
