import React from "react"
import { graphql } from "gatsby"
import handleScroll from "../functions/handleScroll"
import Header from "../components/header"
import Meta from "../components/meta"
import TitleHeader from "../components/titleHeader"
import Headline from "../components/headline"
import Cta from "../components/cta"
import ImageSection from "../components/imageSection"
import Footer from "../components/footer"
import "../styles/pages/_contact.scss"

export default class ContactsPage extends React.Component {
  componentDidMount() {
    window.addEventListener("scroll", handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", handleScroll)
  }

  render() {
    const contactsData = this.props.data.allContentfulContactPage.nodes[0]
    const generalData = this.props.data.allContentfulGeneral.nodes[0]

    return (
      <div className="contacts-page">
        <Meta
          title={contactsData.seo.title}
          description={contactsData.seo.description.description}
          image={contactsData.seo.socialImage.file.url}
          url={this.props.location.href}
          imgAlt={contactsData.seo.socialImage.description}
        />
        <Header />
        <TitleHeader title={contactsData.pageTitle} />
        <div className="content">
          <div className="overview">
            <Headline text={contactsData.headline.json} />
          </div>
          <ul className="contacts">
            {contactsData.ctAs.map((cta, n) => {
              return (
                <li key={n}>
                  <Cta
                    icon={cta.ctaIcon}
                    title={cta.buttonText}
                    blurb={cta.blurb.json}
                    email={cta.link}
                    shadow={generalData.darkShadow}
                  />
                </li>
              )
            })}
          </ul>
          <ImageSection
            images={contactsData.locationImages}
            locationIcon={contactsData.locationIcon}
            text={contactsData.locationText}
          />
        </div>
        <Footer />
      </div>
    )
  }
}

export const query = graphql`
  query contactsPageQuery {
    allContentfulContactPage {
      nodes {
        pageTitle
        headline {
          json
        }
        ctAs {
          ctaIcon {
            description
            file {
              url
            }
          }
          buttonText
          blurb {
            json
          }
          link
        }
        locationImages {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
        locationIcon {
          description
          file {
            url
          }
        }
        locationText
        seo {
          title
          description {
            description
          }
          socialImage {
            description
            file {
              url
            }
          }
        }
      }
    }
    allContentfulGeneral {
      nodes {
        darkShadow {
          description
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
