import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BackgroundImage from "gatsby-background-image"
import "../styles/components/_cta.scss"

const Cta = ({ icon, title, blurb, email, shadow }) => {
  return (
    <div className="cta-item">
      <div className="content">
        <img src={icon.file.url} alt={icon.description} />
        <div className="text">
          <h3>{title}</h3>
          <div className="blurb">{documentToReactComponents(blurb)}</div>
          <a href={`mailto:${email}`}>{email}</a>
        </div>
      </div>
      <BackgroundImage
        fluid={shadow.fluid}
        alt={shadow.description}
        className="shadow"
      ></BackgroundImage>
    </div>
  )
}

export default Cta
